<template lang="pug">
section.homeContact
  #contact.homeContact-background
  .homeContact-container
    .homeContact-info
      h2.homeContact-title Contact me
      .contactBlock
        .contactBlock-name Ian Hurdle
        .contactBlock-rank FOUNDER & DIRECTOR
        a.contactBlock-contact(href='tel:6492471993')
          SvgComponent(name='phone')
          span 649.332.2612
        a.contactBlock-contact(href='mailto:ian.hurdle@theagencyre.com')
          SvgComponent(name='email')
          span ian.hurdle@theagencyre.com
    .homeContact-divider
    .homeContact-form
      .inputGroup(:class='{ _error: $v.name.$error }')
        label.inputGroup-label(for='name') Full name
        input#name.inputGroup-input(v-model.trim='$v.name.$model')
        .error(v-if='$v.name.$error && !$v.name.required') Field is required
      .inputGroup(:class='{ _error: $v.email.$error }')
        label.inputGroup-label(for='email') E-mail
        input#email.inputGroup-input(v-model.trim='$v.email.$model')
        .error(v-if='$v.email.$error && !$v.email.required') Field is required
        .error(v-if='$v.email.$error && !$v.email.email') Email is invalid
      .inputGroup(:class='{ _error: $v.phone.$error }')
        label.inputGroup-label(for='tel') Phone
        input#tel.inputGroup-input(v-model.trim='$v.phone.$model', type='tel')
        .error(v-if='$v.phone.$error && !$v.phone.required') Field is required
        .error(v-if='$v.phone.$error && !$v.phone.numeric') Must accepts only numerics.
      .inputGroup(:class='{ _error: $v.message.$error }')
        label.inputGroup-label(for='message') Message
        input#message.inputGroup-input(v-model.trim='$v.message.$model')
        .error(v-if='$v.message.$error && !$v.message.required') Field is required
      .button._submit(:class='{ _loading: submitStatus == "loading..." }', @click='submit') {{ submitStatus }}
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import { required, email, numeric } from 'vuelidate/lib/validators';
  export default {
    mixins: [validationMixin],
    data() {
      return {
        submitStatus: 'send request',
        name: '',
        email: '',
        phone: '',
        message: '',
      };
    },
    components: {
      SvgComponent: () => import('@/components/SvgComponent'),
    },
    validations: {
      name: { required },
      email: { required, email },
      phone: { required, numeric },
      message: {},
    },
    methods: {
      async submit() {
        if (this.submitStatus == 'OK' || this.submitStatus == 'loading...') {
          return;
        }
        console.log('submit!');
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log('form is invalid!');
          this.submitStatus = 'invalid form';
        } else {
          this.submitStatus = 'loading...';
          const res = await this.$store.dispatch('sendEmail', {
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message,
          });
          this.submitStatus = res.statusText;
          this.$router.push({ name: 'SuccessPage' });
        }
      },
    },
  };
</script>

<style lang="scss">
  .homeContact {
    &-title {
      margin-bottom: 120px;
      @include small {
        margin-bottom: 40px;
      }
      @include tablet {
        margin-bottom: 30px;
        //  margin-top: 56px;
        text-align: center;
      }
    }
    display: contents;
    &-divider {
      width: 2px;
      flex-shrink: 0;
      //  height: 100%;
      background: #c4c4c4;
      margin: 0 150px;
      // align-self: stretch;

      // flex-grow: 1;
      @include middle {
        margin: 0 90px 0 45px;
      }
      @include small {
        margin: 0 px;
      }
    }
    &-background {
      background: #ededed;
    }
    &-container {
      padding: 150px 150px;
      display: flex;
      justify-content: space-between;
      @include middle {
        padding: 100px 80px;
      }
      @include small {
        padding: 80px 50px;
      }
      @include tablet {
        flex-direction: column;
        padding: 56px 0 32px;
      }
      // align-items: center;
    }
    &-info {
      // flex-grow: 1;
      // width: 50%;
      width: 336px;
      @include tablet {
        width: auto;
      }
    }
    &-form {
      // flex-grow: 1;
      // width: 50%;
      width: 336px;
      // padding: 0 150px;
      @include gap(37px, col);
      display: flex;
      flex-direction: column;
      @include tablet {
        margin-top: 40px;
        width: 100%;
      }
    }
    .inputGroup {
      // height: 61px;
      border-bottom: 2px solid #c4c4c4;
      padding-bottom: 10px;
      padding-left: 20px;
      position: relative;
      &-label {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
      }
      &-input {
        font-size: 18px;
        @include tablet {
          width: 100%;
        }
      }
      &._error {
        border-color: $red;
      }
      .error {
        position: absolute;
        bottom: -1.5em;
        left: 20px;
        font-size: 16px;
        line-height: 19px;
        color: $red;
      }
    }

    .contactBlock {
      @include gap(20px, col);
      @include tablet {
        border: solid 5px white;
        padding: 32px;
        svg {
          flex-shrink: 0;
        }
      }
      &-name {
        font-weight: 600;
        font-size: 30px;
      }
      &-rank {
        font-weight: 300;
        font-size: 16px;
      }
      &-contact {
        font-size: 20px;
        line-height: 36px;
        display: flex;
        align-items: center;
        @include gap(20px, row);
        svg {
          color: #d41e34;
        }
        @include tablet {
          font-size: 18px;
        }
      }
    }
  }
</style>
